import React from 'react';
import './navbar.css';
import linkedin from "./linkedinlogo100.png";
import menu from "./menu.svg";
import closeicon from "./close.svg";
import profpic from "./profilepic.jpg";

function toggleMenu() {
    const menu = document.getElementById("mobileNavContainer");
    menu.classList.toggle("open-menu");
    console.log("pressed");
  }

export default function Navbar() {
    return (
        <div className='navbarContainer'>
            <div id="mobileNavContainer" className="mobile-nav">
                <div className="mobile-nav-close-button" >
                <img src={closeicon} alt="" onClick={toggleMenu}/>
                </div>
                <ul>
                <li>
                    <img className="navProfilePic" src={profpic} alt="" />
                </li>
                <li>
                    <text style={{color:"white", fontSize:"22px"}}>
                        Business Consultant<br/>&<br/>Project Manager
                    </text>
                </li>
                <li>
                    <a href="mailto:nik.hirsch6@gmail.com" onClick={toggleMenu}>
                    Email Me<br/>📧
                    </a>
                </li>
                <li>
                    <div className="social-icons">
                        <a href="https://www.linkedin.com/in/nicholas-hirsch-313117117/" target="_blank" rel="noreferrer" onClick={toggleMenu}>
                            <img className="navLinkedin" src={linkedin} alt=""/>
                        </a>
                    </div>
                </li>
                </ul>
            </div>
            <div className="mobile-menu-button" onClick={toggleMenu}>
                <img src={menu} alt="" />
            </div>
            <nav>
                <div className="navContainer">
                    <dive className="navContact">
                        <a className="hide-800" href="mailto:nik.hirsch6@gmail.com">
                            Contact
                        </a>
                        <div className="social-icons hide-800">
                            <div className="socialWrapper">
                                <a href="https://www.linkedin.com/in/nicholas-hirsch-313117117/" target="_blank" rel="noreferrer">
                                    <img className="navLinkedin" src={linkedin} alt="" />
                                </a>
                            </div>
                        </div>
                    </dive>

                </div>
            </nav>
        </div>
    )
}
