export const Education = [
    {
        study: 'B.A. International Business Management',
        uni: '2014-2018 | HS Furtwangen University | V.S., Germany',
        desc: 'A four year program with Internship (Sensata Technologies) and Exchange Semester (Northwest University). Member of Stocks Floor student committee & Moderator of Campus Facebook Page.',
        courses: 'Economics | Mathematics | HR | Sustainable Management | Marketing | Financial Accounting'
    },
    {
        study: 'International Business Exchange Program Ambassador',
        uni: '2016-2017 | Northwest University | Xian, China',
        desc: 'An exchange semester lasting 6 months in mainland China, developing language skills and Eastern business practices.',
        courses: 'Global Advertising Strategy | International Marketing | Chinese Language'
    },
    {
        study: 'B.S. International Business (partial credit)',
        uni: '2012-2014 | Reutlingen University | Reutlingen, Germany',
        desc: 'Incomplete due to change in desired direction. Learned many business topics, specifically luxury brand strategy. First internship (Collapse Entertainment) as part of program.',
        courses: 'Micro/Macro-Economics | Mathematics | Marketing | Database Management | Financial Accounting'
    },
    {
        study: 'Single Course - Communications',
        uni: '2011-2012 | Tarrant County College | NRH, TX, USA',
        desc: 'Completed a communications course to fill the time before moving to Europe. Learned to give presentations.',
        courses: 'Presentation | Communications'
    },
    {
        study: 'B.S. Computer Science (partial credit)',
        uni: '2010-2011 | California Poly-Technic State University | San Luis Obispo, CA, USA',
        desc: 'Incomplete due to change in desired direction. Learned a lot about JS programming and Machine Language.',
        courses: 'Video Game Design | Mathematics | Orienteering | English Literature and Composition'
    },
]