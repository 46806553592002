import React  from 'react';
import styled from "styled-components";
import profpic from "./profilepic.jpg";

const BioContainer = styled.div`
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: lightbrown;
    color: black; 
    padding-inline: 15px;
  
    border-right: 2px solid #000000;
    border-left: 2px solid #000000;

    h1 {
        font-size: 2rem;
    }

    p {
        font-size: .8rem;
    }

    @media (max-width: 800px) {
        width: 100%;
        justify-content: center;
        padding-inline: 0px;
        border-right: none;
        border-left: none;
    }
`;



export default function Bio() {

    return (
        <>
                <BioContainer>
                    <img className="navProfilePic" src={profpic} alt="" />
                    <h1>
                        Nicholas Hirsch
                    </h1>
                    <h2>
                        Creative Problem Solver
                    </h2>
                    <h3>
                        (and Business Consultant)
                    </h3>
                    <p>
                        An international and culturally
                        adaptable business consultant with
                        both large and small business,
                        technically-oriented experience. A
                        passion for graphic communication,
                        marketing, and tech – which has led to
                        an expertise in knowledge management
                        of highly technical content,
                        especially in connected mobility, 
                        governance, A.I., and automation.
                    </p>
                    <p>
                        Currently seeking to expand Web3 
                        infrastructure through Project Management, 
                        Product Management, English Editing, or 
                        DAO Tooling. Always open to new experiences 
                        and opportunities.
                    </p>
                </BioContainer>
        </>
    )
}
