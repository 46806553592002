import React from 'react';
import styled from "styled-components";
import EduComp from './EduComp';
import Bio from './Bio';
import ExpComp from './ExpComp';


const ProfileContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: lightgray;
`;

const ProfileWrapper = styled.div`
    width: 90vw;
    display: flex;
    flex-direction: row;
    align-items: top;
    justify-content: center;
    border-right: 2px solid black;

    @media (max-width: 800px) {
        flex-direction: column;
        border-right: none;
    }
`;

const HistContainer = styled.div`
    width:100%;
`;

const HistWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: top;
    justify-content: center;
`;

const Title = styled.div`
    font-size: 2rem;
    text-align: left;
    padding-left: 15px;
    color: black;
    
    @media (max-width: 800px) {
     margin-top: 20px;
     margin-bottom:5px;
    }
`;

export default function Main() {
    return (
        <ProfileContainer>
            <ProfileWrapper>
                <Bio/>
                <HistContainer>
                    <HistWrapper>
                        <Title>
                            Experience
                        </Title>
                        <ExpComp/>
                        <Title>
                            Education
                        </Title>
                        <EduComp/>
                    </HistWrapper>
                </HistContainer>
            </ProfileWrapper>
        </ProfileContainer>
    )
}
