import React, {useState}  from 'react';
import styled from "styled-components";
import { Education } from './Education.js';
import { IconContext } from 'react-icons';
import { FiPlus, FiMinus } from 'react-icons/fi';


const HistAccordion = styled.div`
`;

const Wrap = styled.div`
    background: #272727;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    cursor:pointer;

    h1 {
        padding: .5rem;
        font-size: 1rem;
    }

    span {
        margin-right: 1.5rem;
    }

    @media (max-width: 800px) {
        h1 {
            font-size: .8rem;
        }
    }
`;

const Dropdown = styled.div`
    background-color: none;
    color: black;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-bottom: 2px solid #777777;
    border-top: 2px solid #777777;
`;

const Textfield = styled.div`
    background-color: none;
    text {
        font-size: 1rem;
        padding: 15px 15px;
    }

    @media (max-width: 800px) {
        text {
            font-size: .8rem;
        }
    }

`;

export default function EduComp() {
    const [clicked, setClicked] = useState(false);

    const toggle = index => {
        if(clicked === index) {
            return setClicked(null);
        }

        setClicked(index);
    }
    return (
        <>
            <IconContext.Provider value={{ color:'#777777', size:'25px'}}>
                <HistAccordion>
                    {Education.map((item, index) => {
                        return (
                            <>
                                <Wrap onClick={() => toggle(index)} key={index}>
                                    <h1>{item.study}</h1>
                                    <span>
                                        {clicked === index ? <FiMinus/> : <FiPlus/>}
                                    </span>
                                </Wrap>
                                {clicked === index ? (
                                    <Dropdown>
                                        <Textfield>
                                            <text>{item.uni}</text>
                                            <br/><br/>
                                            <text>{item.desc}</text>
                                            <br/><br/>
                                            <text>{item.courses}</text>
                                        </Textfield>
                                    </Dropdown>
                                ) : null}
                            </>
                        )
                    })}
                </HistAccordion>
            </IconContext.Provider>
        </>
    )
}