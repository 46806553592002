export const History = [
    {
        position: 'Project Manager - Governance and Knowledge Management',
        company: '2021-Present | Pecunia.Finance DAO| Remote',
        jobdesc: 'Co-Author and Editor of Constitution, White Paper, and architect of core Governance Framework. Considered a core contributor.',
        tools: 'React JS | Jira | Confluence | Kanbo | Excel | Discord | Miro | Github'
    },
    {
        position: 'Business Consultant - Knowledge Management & Chatbot',
        company: '2020-2022 | Mercedes-Benz CAC| Maastricht, Netherlands',
        jobdesc: 'Doubled the response rate of Mercedes me app Support Module A.I.; Edited and restructured over 700 articles; Successfully migrated Knowledge Base, twice (Oracle; Salesforce)',
        tools: 'Oracle | Salesforce | Microsoft Azure & Machine Learning | IBM Watson | Excel | Jira | Kanbo'
    },
    {
        position: 'Mercedes me B2B Specialist - Mercedes-Benz Conneced Mobility Services',
        company: '2019-2020 | Mercedes-Benz CAC | Maastricht, Netherlands',
        jobdesc: 'Subject matter expert on Mercedes me B2B technical solutions and successfully provided CRM for DACH region.',
        tools: 'Oracle | Proprietary Mercedes-Benz CRM | Proprietary Mercedes-Benz Technical Systems | Jira'
    },
    {
        position: 'Marketing Intern - Automotive Segment, European Market',
        company: '2017-2018 | Sensata Technologies | Hengelo, Netherlands',
        jobdesc: 'Standardized marketing materials used by European marketing & sales teams, results applied globally.',
        tools: 'PowerPoint | Excel | OneDrive'
    },
    {
        position: 'Marketing Intern - Media & Entertainment',
        company: '2013-2014 | Collapse Entertainment | Los Angeles, CA, USA',
        jobdesc: 'Learned the American art of face-to-face customer (and talent) relationship management, branding, advertising, marketing, and sales.',
        tools: 'PowerPoint | Excel | OneDrive'
    },
    {
        position: 'Technical Support Technician - Point-of-Sales Systems',
        company: '2009-2011 | Foodtronix | Southlake, TX, USA',
        jobdesc: 'Provided remote and on-site support for malfunctions and instruction, as well as training, programming, and installations of hardware.',
        tools: 'Proprietary POS System | Windows OS | Epson Drivers | LAN'
    },
]